<template>
  <div>
    <div class="head">
      <div class="head_left"></div>
      <div class="search_box">
        <el-input
          v-model.trim="searchValue"
          placeholder="请输入搜索关键字"
          @keydown.enter.native="keySearch"
        ></el-input>
        <span class="search_icon el-icon-search" @click="keySearch"></span>
      </div>
    </div>
    <div v-loading="loading">
      <div class="moment_list" v-if="list.length > 0">
        <div class="moment_item_box" v-for="(item, i) in list" :key="i">
          <div class="moment_item">
            <div class="moment_img_box">
              <img
                :src="item.contentImgUrl || $defaultImg"
                @click="toDetail(item.id)"
                alt=""
              />
              <div class="moment_img_box_box">
                <img src="@/assets/image/page/momentImg.png" />
              </div>
            </div>
            <div class="moment_item_title">
              <div>
                {{ item.contentTitle.slice(0, 30) }}
                {{ item.contentTitle.length > 30 ? "..." : "" }}
              </div>
              <!-- <div
                class="moment_item_time_one"
                v-if="item.contentTitle.length > 30"
              >
                {{ item.contentDatetime.split(" ")[0] }}
              </div> -->
              <div class="moment_item_time_two">
                {{ item.contentDatetime | timeFormat }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          height: 600px;
          display: flex;
          align-content: center;
          justify-content: center;
        "
      >
        <BaseTableEmpty></BaseTableEmpty>
      </div>
    </div>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30]"
        :page-size="pageSize"
        :current-page="pageNum"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import BaseTableEmpty from "@/components/BaseTableEmpty/index.vue";
import { getList } from "@/api/login";
import { time } from "@/utils/image";
export default {
  name: "wonderfulMoment",
  components: { BaseTableEmpty },
  data() {
    return {
      searchValue: "",
      pageSize: 10,
      pageNum: 1,
      total: 0,
      list: [],
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        let res = await getList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          categoryCode: "moments",
          contentTitle: this.searchValue,
        });
        this.loading = false;
        if (res.code == "0") {
          let arr = res.data.cmsContents.dataList || [];
          arr.forEach((item) => {
            item.time = time(item.contentDatetime);
          });
          this.list = arr;
          this.total = res.data.cmsContents.total;
        } else {
          this.list = [];
          this.total = 0;
        }
      } catch (error) {
        this.list = [];
        this.total = 0;
        this.loading = false;
      }
    },
    keySearch() {
      this.pageNum = 1;
      this.getList();
    },
    handleSizeChange(size) {
      this.pageNum = 1;
      this.pageSize = size;
      this.getList();
    },
    handleCurrentChange(page) {
      this.pageNum = page;
      this.getList();
    },
    toDetail(id) {
      this.$router.push({
        path: "/detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped>
.head {
  background-color: #d80c24;
  height: 0.52rem;
  padding-left: 1.06rem;
  padding-right: 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head_left {
  display: flex;
  align-items: center;
}
.search_box {
  position: relative;
  width: 3.25rem;
  height: 0.36rem;
  border-radius: 0.18rem;
  overflow: hidden;
}
.search_box /deep/ .el-input__inner {
  height: 0.36rem;
  padding-right: 50px;
}
.search_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.16rem;
  font-size: 0.18rem;
  cursor: pointer;
}
.moment_list {
  padding: 0.2rem 0.7rem;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
}
.moment_item_box {
  width: 33.3%;
  margin-bottom: 20px;
  padding-right: 20px;
}
.moment_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.moment_img_box {
  position: relative;
  width: 100%;
  height: 2.44rem;
}
.moment_img_box img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.moment_img_box_box {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 0.24rem;
  height: 0.24rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.moment_img_box_box img {
  height: 0.17rem;
  width: 0.17rem;
}
.moment_item_title {
  position: relative;
  padding: 10px 15px;
  width: 100%;
  color: #3e3a39;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  letter-spacing: 1.5px;
  font-weight: 700;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.moment_item_time_one {
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: #3e3a39;
  font-size: 12px;
  font-weight: 500;
}
.moment_item_time_two {
  color: #3e3a39;
  font-size: 12px;
  font-weight: 500;
  width: 115px;
  flex-shrink: 0;
  text-align: right;
}

.page_box {
  margin: 0.3rem 0;
  display: flex;
  justify-content: center;
}
.page_box
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #d80c24;
}
</style>
